<template>
  <div class="fill-height d-flex flex-column ">
    <div class="userInfoStepper d-flex flex-column">
      <div class="titleBox">
        <!--        {{ $t('userOnboarding.specifications') }}-->
      </div>
      <div class="titleProfileSection mt-10">
        <v-icon small color="#4597DF">mdi-circle</v-icon>
        <!--        {{ $t('section_title.personalInfo') }}-->
        لطفا اطلاعات حساب کاربری خود را تکمیل کنید
      </div>
      <div class="boxProfile pa-10">
        <v-form ref="profileFrom" @submit.prevent="updateProfile" @keypress.native.enter="updateProfile">
          <v-row>
            <v-col cols="12" lg="4">
              <v-text-field class="profileTextFiled" outlined
                            prepend-inner-icon="mdi-account-outline"
                            :label="$t('profileView.name')"
                            :hint="$t('profileView.name_hint')"
                            persistent-hint
                            v-model="profile.firstname"
                            id="UserName"
                            :rules="requiredRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field class="profileTextFiled" outlined
                            prepend-inner-icon="mdi-account-outline"
                            :label="$t('profileView.family')"
                            id="UserFamily"
                            v-model="profile.lastname"
                            :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="ltrInput">

                <v-text-field class="profileTextFiled" outlined
                              prepend-inner-icon="mdi-account-outline"
                              :label="$t('profileView.en_name')"
                              v-model="profile.en_name"
                              id="UserName"
                              :rules="requiredRules"
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="ltrInput">
                <v-text-field class="profileTextFiled" outlined
                              prepend-inner-icon="mdi-account-outline"
                              :label="$t('profileView.en_family')"
                              id="UserFamily"
                              v-model="profile.en_family"
                              :rules="requiredRules"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="ltrInput">
                <v-text-field class="profileTextFiled" outlined
                              prepend-inner-icon="mdi-cellphone"
                              :label="$t('profileView.tell')"
                              placeholder="+1 555 123 4567"
                              :rules="phoneRules"
                              id="UserTell"
                              v-model="phone"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" lg="4">
              <v-text-field class="profileTextFiled" outlined
                            prepend-inner-icon="mdi-email"
                            :label="$t('profileView.email')"
                            id="UserFamily"
                            v-model="profile.email"
                            disabled
                            readonly
                            :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" @click="showDialog = true">
              <v-text-field class="profileTextFiled" outlined
                            v-model="userInfo.birthDate"
                            :value="userInfo.birthDate | toJalali(isJalali)"
                            :rules="requiredRules"
                            :label="$t('profileView.birthDate')"
              >
              </v-text-field>
              <DatePicker @close="showDialog=false"
                          :dialog="showDialog"
                          @jalaliChange="changeIsJalali"
                          @input="showDialog = false"
                          v-model="userInfo.birthDate"
              ></DatePicker>
            </v-col>
            <v-col cols="12" lg="4">

              <v-autocomplete class="profileTextFiled"
                              placeholder="تایپ کنید..."
                              outlined :rules="requiredRules" v-model="country" item-text="label" item-value="id"
                              :items="contires"
                              prepend-inner-icon="mdi-map-marker"
                              :label="$t('userOnboarding.country')"></v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4">

              <v-text-field placeholder="tehran" class="profileTextFiled" outlined id="UserCity" :rules="requiredRules"
                            prepend-inner-icon="mdi-map-marker"
                            :label="$t('userOnboarding.city')"
                            v-model="profile.city"></v-text-field>
            </v-col>
            <v-col cols="12" lg="4">

              <v-autocomplete class="profileTextFiled"
                              outlined :rules="requiredRules" v-model="profile.tz" :items="timezonesList"
                              prepend-inner-icon="mdi-timer-outline"
                              :label="$t('userOnboarding.tz')"
                              hint="زمان های نمایش‌ داده بر اساس این منطقه زمانی می‌باشد"
                              persistent-hint
              ></v-autocomplete>

            </v-col>
            <v-col cols="12" lg="4">
              <v-select v-model="profile.knway" :rules="requiredRules" outlined class="profileTextFiled"
                        :label="$t('knwayLabel')" :items="knway" item-text="title" item-value="value"></v-select>
            </v-col>
            <v-col cols="12" lg="4">
              <div class="" v-if="profile.knway == 'other'">
                <v-text-field v-model="profile.knway_meta" :rules="requiredRules" outlined class="profileTextFiled"
                              :label="$t('knwayLabel')" :items="knway" item-text="title"
                              item-value="value"></v-text-field>
              </div>
            </v-col>
            <v-col cols="12" lg="4"></v-col>
          </v-row>
        </v-form>
      </div>
      <!--      <div class="formArea  flex-grow-1">-->
      <!--        <v-form ref="profileFrom" @submit.prevent="updateProfile" @keypress.native.enter="updateProfile">-->
      <!--          <v-window v-model="userInfoModel" reverse touchless>-->
      <!--            <v-window-item :value="1">-->
      <!--              <div class="ma-5" v-if="userInfoModel == 1">-->
      <!--                <v-text-field class="profileTextFiled" outlined-->
      <!--                              prepend-inner-icon="mdi-account-outline"-->
      <!--                              :label="$t('profileView.name')"-->
      <!--                              v-model="profile.firstname"-->
      <!--                              id="UserName"-->
      <!--                              :rules="requiredRules"-->
      <!--                ></v-text-field>-->

      <!--                <div class="ltrInput mt-3">-->


      <!--                </div>-->
      <!--              </div>-->
      <!--            </v-window-item>-->
      <!--            &lt;!&ndash;            <v-window-item :value="2">&ndash;&gt;-->
      <!--            &lt;!&ndash;              <div class="ma-5" v-if="userInfoModel == 2">&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div class="genderArea  d-flex justify-space-between">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <div v-for="(gender , i ) in genders" :key="i" @click="selectGender(gender.key)">&ndash;&gt;-->
      <!--            &lt;!&ndash;                    <div class="genderIcon " :class="prepareClass(gender)"></div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                    <p :class="userInfo.gender == gender.key && 'ActiveGenderText' ">&ndash;&gt;-->
      <!--            &lt;!&ndash;                      {{ gender.title }}&ndash;&gt;-->
      <!--            &lt;!&ndash;                    </p>&ndash;&gt;-->
      <!--            &lt;!&ndash;                  </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->

      <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;            </v-window-item>&ndash;&gt;-->
      <!--            &lt;!&ndash;            <v-window-item :value="3">&ndash;&gt;-->
      <!--            &lt;!&ndash;              <div class="ma-5" v-if="userInfoModel == 3">&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div id="UserChildOrder">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-select&ndash;&gt;-->
      <!--            &lt;!&ndash;                      :rules="requiredRules"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      :label="$t('userOnboarding.childOrder')"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      v-model="profile.children_order_status"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      :items="childOrder"&ndash;&gt;-->
      <!--            &lt;!&ndash;                  ></v-select>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div :class="profile.married" id="UserMarried">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-select :rules="requiredRules"&ndash;&gt;-->
      <!--            &lt;!&ndash;                            :label="$t('userOnboarding.maritalStatus')"&ndash;&gt;-->
      <!--            &lt;!&ndash;                            :items="maritalStatus"&ndash;&gt;-->
      <!--            &lt;!&ndash;                            id="UserMarried"&ndash;&gt;-->
      <!--            &lt;!&ndash;                            v-model="profile.married"&ndash;&gt;-->
      <!--            &lt;!&ndash;                            item-value="key" item-text="title"></v-select>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div id="UserChildCount">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-select&ndash;&gt;-->
      <!--            &lt;!&ndash;                      :label="$t('userOnboarding.childCount')"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      :items="childCount"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      item-text="title"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      item-value="key"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      id="UserChild"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      v-model="profile.children_count">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  </v-select>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;            </v-window-item>&ndash;&gt;-->
      <!--            &lt;!&ndash;            <v-window-item :value="4">&ndash;&gt;-->
      <!--            &lt;!&ndash;              <div class="ma-5" v-if="userInfoModel == 4">&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div id="UserJob">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-select&ndash;&gt;-->
      <!--            &lt;!&ndash;                      :items="jobs"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      item-text="title"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      item-value="key"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      :label="$t('userOnboarding.jobStatus')"&ndash;&gt;-->
      <!--            &lt;!&ndash;                      v-model="profile.job"></v-select>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div id="UserCountry">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-autocomplete :rules="requiredRules" v-model="country" item-text="label" item-value="id"&ndash;&gt;-->
      <!--            &lt;!&ndash;                                  :items="contires"&ndash;&gt;-->
      <!--            &lt;!&ndash;                                  prepend-inner-icon="mdi-map-marker"&ndash;&gt;-->
      <!--            &lt;!&ndash;                                  :label="$t('userOnboarding.country')"></v-autocomplete>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                <div id="UserTz">&ndash;&gt;-->
      <!--            &lt;!&ndash;                  <v-autocomplete :rules="requiredRules" v-model="profile.tz" :items="timezones"&ndash;&gt;-->
      <!--            &lt;!&ndash;                                  prepend-inner-icon="mdi-timer-outline"&ndash;&gt;-->
      <!--            &lt;!&ndash;                                  :label="$t('userOnboarding.tz')"></v-autocomplete>&ndash;&gt;-->
      <!--            &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;                <v-text-field outlined id="UserCity" :rules="requiredRules" prepend-inner-icon="mdi-map-marker"&ndash;&gt;-->
      <!--            &lt;!&ndash;                              :label="$t('userOnboarding.city')"&ndash;&gt;-->
      <!--            &lt;!&ndash;                              v-model="profile.city"></v-text-field>&ndash;&gt;-->
      <!--            &lt;!&ndash;              </div>&ndash;&gt;-->
      <!--            &lt;!&ndash;            </v-window-item>&ndash;&gt;-->
      <!--          </v-window>-->
      <!--        </v-form>-->
      <!--      </div>-->
      <!--      <div class="segmentControl" v-if="!$route.query.step">-->
      <!--        <div class="d-flex justify-space-between flex-row-reverse">-->
      <!--          <div class="dots " v-for="n in 4" :key="n" :class="n == userInfoModel && 'activeDots'"></div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="actionArea mt-5 d-flex justify-start">
        <div class="flex-grow-1 text-left">
          <v-btn id="nextBtn" class="confirmAndContinue" :disabled="loadingStatus" elevation="0" color="#46b1a1"
                 @click="updateProfile">
            {{ $t('edit') }}
          </v-btn>
        </div>
        <div class="mr-2">
          <!--          <v-btn color="#ecf7f5" id="returnBtn" :disabled="loadingStatus || userInfoModel == 1 && !$route.query.step"-->
          <!--                 class="returnBtn" elevation="0" @click="returnBtn">-->
          <!--            {{ $t('return') }}-->
          <!--          </v-btn>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import {mapGetters} from "vuex";
import {parsePhoneNumberFromString} from "libphonenumber-js";
import moment from "moment-timezone";
import AuthMixins from "@/mixins/AuthMixins";
import DatePicker from "@/components/Calendar/DatePicker";
import momentJalali from "moment-jalaali";
import EventsMixins from "@/mixins/EventsMixins";


/*eslint-disable*/
export default {
  mixins: [AuthMixins, EventsMixins],
  components: {DatePicker},
  data() {
    return {
      selectedGender: null,
      showDialog: false,
      userInfoModel: 1,
      birthDate: null,
      phone: null,
      country: null,
      profile: {},
      isJalali: false,
      knway: [
        {value: "google", title: this.$t('knway.google')},
        {value: "twitter", title: this.$t('knway.twitter')},
        {value: "instagram", title: this.$t('knway.instagram')},
        {value: "podcast", title: this.$t('knway.podcast')},
        {value: "bannerads", title: this.$t('knway.bannerads')},
        {value: "friends", title: this.$t('knway.friends')},
        {value: "youtube", title: this.$t('knway.youtube')},
        // {value: "site", title: this.$t('knway.site')},
        {value: "other", title: this.$t('knway.other')},
      ],
      jobs: [
        {
          title: this.$t('jobs.employed'),
          key: 'employed',
        },
        {
          title: this.$t('jobs.unemployed'),
          key: "unemployed"
        },
        {
          title: this.$t('jobs.housewife'),
          key: "housewife",
        },
        {
          title: this.$t('jobs.student'),
          key: "student"
        },
        {
          title: this.$t('jobs.other'),
          key: 'other'
        },
      ],
      genders: [
        {
          title: this.$t('gender.other'),
          key: 'OTHER',
          class: 'otherGender'
        },
        {
          title: this.$t('gender.male'),
          key: 'MALE',
          class: 'menGender'
        },
        {
          title: this.$t('gender.female'),
          key: 'FEMALE',
          class: 'womenGender'
        },
      ],
      childOrder: [1, 2, 3, 4, 5],
      childCount: [
        {
          title: this.$t('profileView.moreInfoModal.noChild'),
          key: 0,
        },
        {
          title: '1',
          key: 1
        },
        {
          title: '2',
          key: 2
        },
        {
          title: '3',
          key: 3
        },
        {
          title: '4',
          key: 4
        }
      ],
      maritalStatus: [
        {
          key: 'single',
          title: this.$t('relationships.single')
        },
        {
          key: 'married',
          title: this.$t('relationships.married')
        },
        {
          key: 'divorced',
          title: this.$t('relationships.divorced')
        },
      ],
      timezonesList: null,
    }
  },
  computed: {
    ...mapGetters({
      'userInfo': 'getUserInfo',
    }),


  },
  methods: {
    changeIsJalali(val) {
      this.isJalali = val;
    },
    prepareDeepCopyProfile() {
      this.phone = this.userInfo.cel
      this.selectedGender = this.userInfo.gender
      if (this.userInfo.country) {
        this.country = this.userInfo.country.id
      }
      this.profile = JSON.parse(JSON.stringify(this.userInfo))
    },
    selectGender(key) {
      this.userInfo.gender = key
      // console.log(this.userInfo.gender)
    },
    prepareClass(gender) {
      if (this.userInfo.gender == gender.key) {
        return 'activeGender' + ' ' + gender.class + " " + gender.key
      }
      return gender.class + " " + gender.key
    },
    returnBtn() {
      if (this.$route.query.step) {
        this.$router.push({name: 'setting'})
      } else {
        this.userInfoModel--
      }
    },
    updateProfile() {
      if (this.$refs.profileFrom.validate()) {
        let payload = this.preparePayload(this.profile)
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'editProfile', '', payload).then((resp) => {
          this.WebengageSetUserRequiredFiled(resp.data.profile)
          this.$router.push({name: 'dashboard'})
          this.$emitEvent('notify', {
            title: this.$t('userOnboarding.notify.done'),
            color: 'green',
            id: 'notify_success'
          })
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })

      }
    },
    preparePayload(profile) {
      // console.log(profile, 'interd')
      let final = {}
      let payload = {
        "familiar_by": profile.knway,
        "familiar_by_meta": profile.knway_meta,
        name: profile.firstname,
        family: profile.lastname,
        mobile: parsePhoneNumberFromString(this.phone).number,
        birth_date: this.isJalali ? this.jalaliToGregorian(this.userInfo.birthDate) : this.userInfo.birthDate,

        gender: this.userInfo.gender,
        marital_state: this.profile.married,
        children_count: this.profile.children_count,
        children_order_status: this.profile.children_order_status,
        job: this.profile.job,
        country_id: this.country,
        city: this.profile.city,
        timezone: this.profile.tz,
        "en_name": this.profile.en_name,
        "en_family": this.profile.en_family
      }
      // console.log(payload)
      Object.keys(payload).forEach(item => {
        if (payload[item] != null) {
          final[item] = payload[item]
        }
      })
      return final
    },
    prepareDefaultTimezone() {
      try {
        if (this.profile) {
          let options = {timeZoneName: 'long'};
          let timezone = Intl.DateTimeFormat(undefined, options).resolvedOptions().timeZone;
          if (timezone) {
            console.log(timezone, '21212')
            if (this.profile.tz == 'UTC') {
              this.profile.tz = timezone
            }
          }
        }
      } catch (er) {
        console.log(er)
      }
    }

  },
  mounted() {

    this.$refs.profileFrom.validate()

    let times = JSON.parse(JSON.stringify(this.timezones));
    this.timezonesList = times.map((item) => {
      item.text = item.text + '  ' + moment.tz(item.text).format('Z');
      return item;
    });


    if (this.$route.query.step) {
      this.userInfoModel = Number(this.$route.query.step)
    }
    this.$emitEvent('setLoading', true)
    this.requesting('auth', 'getProfile').finally(() => {
      this.$emitEvent('setLoading', false)
      this.prepareDeepCopyProfile()
      this.prepareDefaultTimezone()

    })


  },
  filters: {
    toJalali(date, isJalali) {
      if (date) {
        if (isJalali) {
          return momentJalali(date, "YYYY-M-DD").format('jYYYY/jMM/jDD');
        } else {
          return date;
        }
      }
      return date;
    },
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .userInfoStepper {
    width: 100% !important;
    padding: 20px 20px !important;
  }
}

.profileTextFiled {
  border-radius: 12px;
}

.userInfoStepper {
  /*min-height: 400px;*/
  /*border-radius: 15px;*/
  /*background-color: white;*/
  /*width: 40%;*/
  /*margin: 0px auto;*/
  /*box-shadow: 0px 0px 10px 5px #ddd;*/
  /*padding: 30px 40px;*/
  /*text-align: center;*/
  width: 90%;
}

.titleBox {
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: rgb(0, 0, 0)
}

.dots {
  width: 10px;
  height: 10px;
  background-color: rgb(219, 219, 219);
  border-radius: 50%;
}

.formArea {
  width: 100%;
  margin: 0px auto;
}

.segmentControl {
  width: 30%;
  margin: 0px auto;
}

.activeDots {
  background: #4576d8;
}

.confirmAndContinue {
  height: 45px !important;
  color: white;
  font-size: 16px !important;
  border-radius: 10px !important;
  /*width: 100% !important;*/
  font-weight: bold;
}

.returnBtn {
  height: 45px !important;
  padding-right: 35px !important;
  padding-left: 35px !important;
  border-radius: 10px !important;
  background-color: rgb(70, 177, 161);
  color: #46b1a1;
}

.test {
  /*mask: url("../../assets/userOnboarding/other.svg");*/
  mask-size: contain;
  width: 100px;
  height: 100px;
}

.test img {
  fill: red !important;
  color: red !important;
}

.boxProfile {
  background: #FFFFFF;
  box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  padding: 20px;

}
</style>